import {ERmxBoxProgram, type UnsavedRmxBox, type UnsavedRmxBoxPort, type UnsavedRmxBoxSource} from '../../types/RmxBox';
import {type AssetGroup} from '../../redux/entities/assetGroup';
import {type Site} from '../../redux/entities/site';
import {type Dealer} from '../../redux/entities/dealer';
import {type Installation, type InstallationFormFields} from '../../redux/entities/installation';
import {
  type DataPoint,
  type DataPointType
} from '../../redux/entities/dataPoint';
import {type Company} from '../../redux/entities/company';
import {type AssetType} from '../../redux/entities/assetType';
import {type SPType} from '../../redux/entities/spType';
import {type AlertSetPoint, type ContainsDelays, type RequiredOrderableDPAssignment} from '../../redux/abstract/dataPointAssignment';
import {type DataPointTemplate} from '../../redux/abstract/dataPointTemplate';
import {type CurrentSpec} from '../../redux/entities/installationEntities';
import {type Manufacturer} from '../../redux/entities/manufacturers';
import {
  type AlertBitByBitCheckGroup,
  type AlertCodeCheck,
  type AlertCodeCheckGroup,
  type AlertConversion,
  type AlertConversionForm,
  type AlertConversionType, type AlertOneToOneCheck, type BaseAlertCheck, getAlertConversionLabel, makeReferencesTrigger
} from '../../redux/entities/conversions/alertConversion';
import {makeRevisionEntity} from '../../types/Revision';
import {
  type AlertBitByBitConversionRevision,
  type AlertCodeConversionRevision,
  type AlertConversionRevision, type AlertOneToOneConversionRevisionForm, isAlertOneToOneConversionRevision, type TriggerWithOneToOneCheck
} from '../../redux/entities/conversions/alertConversionRevision';
import {
  type AlertBitByBitAssignmentGroupFields,
  type AlertBitByBitAssignmentGroupForm
} from '../../redux/entities/alertBitByBitAssignmentGroup';
import { arrayToRecord, cast, isNotNullOrUndefined } from '../index';
import {
  type AlertBitByBitAssignmentGroupRevision,
  type BitAssignment, type BitNumber
} from '../../redux/entities/alertBitByBitAssignmentGroupRevisions';
import {type Entity} from '../../types/Entity';
import {convertToVariableName} from '../string';
import {getUUID} from 'src/util/uuid';
import {type DropdownOptions} from 'src/components/util/form-components/SearchableDropdown/SearchableDropdown';
import {type User, type UserPreferences} from '../../redux/entities/user';
import {makeAlertScheduleWeekDays, type UserAlertPreference} from '../../redux/entities/userAlertPreference';
import {SerialTransmitter} from 'src/common/types/RmxBoxOptions';
import {RmxBoxRouterIdDragon, RmxBoxTypeIdType1} from 'src/common/DatabaseHardCodedConstants';
import {makeExternalIntIdObj} from 'src/common/redux/abstract/IExternalIntId';
import {makeReferencesManufacturerIds} from 'src/common/util/factory/shared';
import {type ControllerType} from 'src/common/redux/entities/controllerType';
import {type ConnectionKit} from 'src/common/redux/entities/connectionKit';
import {type ProtocolAdapter} from 'src/common/redux/entities/protocolAdapter';
import {type ControllerTypeRevision} from 'src/common/redux/entities/controllerTypeRevision';

export function makeAssetGroup(): AssetGroup {
  return {
    id: 0,
    name: '',
    ...makeHasDataPointRequirements(),
    assetTypeIds: []
  };
}

export function makeHasDataPointRequirements<T>(): DataPointTemplate<T> {
  return {
    dataDataPointAssignments: [],
    productionStatusAssignments: [],
    operationalStatusAssignments: [],
    specAssignments: []
  };
}

export function makeAssetType(assetGroupId: number): AssetType {
  return {
    id: 0,
    name: '',
    assetGroupId: assetGroupId
  };
}

export function makeSpType(): SPType {
  return {
    id: 0,
    name: '',
    primaryAssetGroupId: 0,
    ...makeHasDataPointRequirements(),
    allowableAssetGroups: [],
    allowableSPTypes: []
  };
}

export function makeRmxBox(): UnsavedRmxBox {
  return ({
    id: 0,
    name: '',
    serialNumber: '',
    ipAddress: null,
    macAddress: '',
    rmxBoxTypeId: RmxBoxTypeIdType1,
    serialTransmitter: SerialTransmitter.Laird,
    wifi: true,
    companyId: 0,
    installationId: 0,
    timeoutMinutes: 30,
    routerId: RmxBoxRouterIdDragon,
    systemID: null,
    simICCID: null,
    routerIMEI: null,
    phoneNumber: null,
    channel: null,
    countryId: null,
    carrierId: null,
    program: ERmxBoxProgram.RMX_PLC
  });
}

export function makeRmxBoxSource(portId: number, node: number = 0): UnsavedRmxBoxSource {
  return {
    firstConnectTimeoutSeconds: 2,
    ipAddress: '',
    maxRegisters: 32,
    maxBits: 512,
    parentSourceId: null,
    registerOffset: 0,
    name: '',
    node: node,
    pollingRateSeconds: 0.5,
    pollRetries: 2,
    portNumber: 502,
    rmxBoxPortId: portId,
    sourceTimeoutMinutes: 30,
    isRmxBox: false,
    isHidden: false
  };
}

export function makeRmxBoxPort(): UnsavedRmxBoxPort {
  return {
    baudRate: '9600',
    stopBits: 1,
    dataBits: 8,
    parity: 'None'
  };
}

export function makeDealer(): Dealer {
  return ({
    id: 0,
    name: '',
    uuid: getUUID()
  });
}

export function makeManufacturer(): Manufacturer {
  return {
    id: 0,
    name: '',
    isUniversal: false
  };
}

export function makeCompany(dealerId: number = 0): Company {
  return ({
    id: 0,
    name: '',
    dealerId: dealerId,
    archivedAt: null,
    uuid: getUUID()
  });
}

export function makeSite(companyId: number = 0): Site {
  return ({
    id: 0,
    name: '',
    companyId: companyId,
    ...makeExternalIntIdObj(),
    uuid: getUUID()
  });
}

export function makeInstallation(siteId: number): InstallationFormFields {
  const formFields = makeInstallationFormFields();
  return ({
    ...formFields,
    siteId: siteId
  });
}

export function makeInstallationFormFields(i?: Installation): InstallationFormFields {
  return {
    id: i?.id ?? 0,
    name: i?.name ?? '',
    siteId: i?.siteId ?? 0,
    mainRmxBoxAssetId: i?.mainRmxBoxAssetId ?? null,
    useGps: i?.useGps ?? false,
    alertIndicatorsEnabled: i?.alertIndicatorsEnabled ?? false,
    activated: i?.activated ?? false
  };
}

export function makeDataPoint(type: DataPointType, unitGroupId: number = 0, statusTrue?: string, statusFalse?: string, isGlobalOrNullForLocal?: boolean): DataPoint {
  const dp: DataPoint = ({
    id: 0,
    name: '',
    uuid: getUUID(),
    variableName: '',
    dataPointType: type,
    unitGroupId: unitGroupId,
    displayUnitId: null,
    defaultDecimalPlaces: 0,
    isMinOrMax: false,
    maxDataPointId: null,
    minDataPointId: null,
    assetGroupId: null,
    systemProcessTypeId: null,
    associatedDataPointId: null,
    ndpaAlertId: null,
    alertTypeId: null,
    alertGroupId: null,
    isGlobalOrNullForLocal: isGlobalOrNullForLocal ?? true
  });
  if (statusTrue !== undefined) {
    dp.statusTrue = statusTrue;
  }
  if (statusFalse !== undefined) {
    dp.statusFalse = statusFalse;
  }
  return dp;
}


export function makeAlertStatusDp(name: string, unitGroupId: number, associatedDpId: number|null, ndpaAlertId: number|null): DataPoint {
  const uniqueId =  getUUID();
  return ({
    ...makeDataPoint('AlertStatusRegister', unitGroupId),
    name: name,
    uuid: uniqueId,
    associatedDataPointId: associatedDpId,
    variableName: convertToVariableName(name),
    ndpaAlertId: ndpaAlertId,
    isGlobalOrNullForLocal: null
  });
}

export function makeAlertTriggerDp(name: string, triggerUnitGroupId: number): DataPoint {
  const uniqueId =  getUUID();
  return ({
    ...makeDataPoint('AlertTrigger', triggerUnitGroupId),
    name: name,
    uuid: uniqueId,
    variableName: convertToVariableName(name),
    isGlobalOrNullForLocal: null
  });
}

export function makeManufacturerAlertCodeDp(name: string, manufacturerUnitGroupId: number): DataPoint {
  const uniqueId =  getUUID();
  return ({
    ...makeDataPoint('ManufacturerAlertCode', manufacturerUnitGroupId),
    name: name,
    uuid: uniqueId,
    variableName: convertToVariableName(name),
    isGlobalOrNullForLocal: null
  });
}

export function makeDataPointAssignment(dataPoint: Entity, order: number, required?: boolean): RequiredOrderableDPAssignment {
  return ({
    id: 0,
    dataPointId: dataPoint.id,
    required: required ? required : false,
    order: order
  });
}

export function makeCurrentSpec(dpId: number, isString: boolean): CurrentSpec {
  return {
    specValueId: 0,
    specValue: {
      id: 0,
      assetId: null,
      dataPointId: dpId,
      value: isString ? null : null,
      stringValue: isString ? '' : null,
      unitId: 0
    }
  };
}

export function makeAlertConversionFormFromType(type: AlertConversionType): AlertConversionForm {
  return {
    conversion: {
      name: '',
      assetGroupId: 0,
      ...makeReferencesManufacturerIds(),
      type: type
    },
    revision: makeAlertConversionFormRevision(type),
    descriptionAndResponses: []
  };
}

export function makeAlertConversionFormRevision(type: AlertConversionType) {
  const common: AlertConversionRevision = {
    id: 0,
    templateSources: [],
    ...makeRevisionEntity(`Create ${getAlertConversionLabel(type)}` ),
    alertConversionId: 0,
    hasEverBeenAssigned: false,
    type: type,
    validateDuplicateChecks: true
  };
  return type === 'AlertCodeConversion' ? cast<AlertCodeConversionRevision>({
    ...common,
    alertCodeCheckGroups: [],
    triggers: [],
    manufacturerAlertCodeRegisters: [],
    type: 'AlertCodeConversion'
  }) : type === 'AlertOneToOneConversion' ?
    cast<AlertOneToOneConversionRevisionForm>({
      ...common,
      triggers: [],
      type: 'AlertOneToOneConversion'
    })
    : cast<AlertBitByBitConversionRevision>({
    ...common,
    alertBitByBitCheckGroups: [],
    alertStatusAssignments: [],
    type: 'AlertBitByBitConversion'
  });
}

export function makeAlertConversionForm(conversion: AlertConversion, revision: AlertConversionRevision): AlertConversionForm {
  return {
    conversion: {
      name: conversion.name,
      assetGroupId: conversion.assetGroupId,
      manufacturerIds: conversion.manufacturerIds,
      type: conversion.type
    },
    revision: convertAlertConversionRevisionToFormRevision(verifyAlertConversionReferences(revision)),
    descriptionAndResponses: []
  };
}

function convertAlertConversionRevisionToFormRevision<T extends AlertConversionRevision>(rev: T): T {
  if (isAlertOneToOneConversionRevision(rev)) {
    const checksByTrigger = arrayToRecord(rev.oneToOneAlertChecks.filter(c => !!c.triggerUuid), c => c.triggerUuid!, c => c);
    return cast<AlertOneToOneConversionRevisionForm>({
      ...rev,
      type: 'AlertOneToOneConversion',
      triggers: rev.triggers.map<TriggerWithOneToOneCheck>(t => ({
        ...t,
        dataPointId: t.dataPoint.id,
        alertOneToOneCheck: checksByTrigger[t.dataPoint.uuid!] ?? makeAlertOneToOneCheck(t.dataPoint.uuid!)
      }))
    }) as unknown as T;
  }
  return rev;
}

function verifyAlertConversionReferences(rev: AlertConversionRevision) {
  if (rev.type === 'AlertBitByBitConversion') {
    return verifyAlertBitByBitConversionReferences(rev as AlertBitByBitConversionRevision);
  }
  return rev;
}

function verifyAlertBitByBitConversionReferences(rev: AlertBitByBitConversionRevision) {
  const alertStatusIds = rev.alertBitByBitCheckGroups.map(g => g.alertStatusDataPointUuid);
  const alertStatusAssignments = rev.alertStatusAssignments.filter(a => alertStatusIds.findIndex(id => id === a.dataPoint!.uuid) !== -1);
  if (rev.alertStatusAssignments.length !== alertStatusAssignments.length) {
    // tslint:disable-next-line:no-console
    console.warn(
      `AlertBitByBitConversion Id: ${rev.alertConversionId}, RevisionId: ${rev.id} has more alert status assignments than check groups!`
    );
  }
  return {
    ...rev,
    alertStatusAssignments: alertStatusAssignments
  };
}

export function makeAlertCodeCheckGroup(): AlertCodeCheckGroup {
  return {
    manufacturerAlertCode: '' as unknown as number,
    alertCodeChecks: [makeAlertCodeCheck()]
  };
}

export function makeBaseAlertCheck(triggerUuid?: string): BaseAlertCheck {
  return {
    dataPointAssociated: true,
    ...makeReferencesTrigger(triggerUuid),
    associatedDataPointId: null,
    alertGroupId: '' as unknown as number,
    alertTypeId: null,
    ndpaAlertId: null
  };
}

export function makeAlertCodeCheck(): AlertCodeCheck {
  return {
    manufacturerAlertCodeDataPointUuid: '',
    hasTrigger: true,
    ...makeBaseAlertCheck()
  };
}

export function makeAlertOneToOneCheck(triggerUuid?: string): AlertOneToOneCheck {
  return {
    ...makeBaseAlertCheck(triggerUuid)
  };
}

export function makeAlertBitByBitAssignmentGroupForm(
  template?: AlertBitByBitAssignmentGroupFields,
  revision?: AlertBitByBitAssignmentGroupRevision): AlertBitByBitAssignmentGroupForm {
  return {
    template: template ?? {
      name: '',
      ...makeReferencesManufacturerIds()
    },
    revision: revision ?? {
      id: 0,
      alertBitByBitAssignmentGroupId: 0,
      numberOfBits: '' as unknown as BitNumber,
      dataPointAssociated: '' as unknown as boolean,
      bitAssignments: [],
      ...makeRevisionEntity('Create Template')
    }
  };
}

export function makeBitAssignment(): BitAssignment {
  return {
    id: 0,
    bit: '' as unknown as number,
    activeState: '' as unknown as boolean,
    alertGroupId: '' as unknown as number,
    alertTypeId: null
  };
}

export function makeBitByBitCheckGroup(
  alertStatusUuid: string, assignmentGroupId: number, assignmentGroupRevisionId: number): AlertBitByBitCheckGroup {
  return {
    alertStatusDataPointUuid: alertStatusUuid,
    alertBitByBitAssignmentGroupId: assignmentGroupId,
    alertBitByBitAssignmentGroupRevisionId: assignmentGroupRevisionId
  };
}

export function makeAlertSetPoint(alertGroupId: number, alertTypeId: number): AlertSetPoint {
  return {
    alertGroupId: alertGroupId,
    alertTypeId: alertTypeId,
    ...makeReferencesTrigger(),
    comparison: 'Lessor',
    dataPointSetPoint: null,
    manualValue: null,
    ...makeDelays(),
    type: 'Manual',
    manualValueUnitId: null,
    luid: getUUID(),
    dataPointSetPoint2: null,
    comparison2: null,
    manualValue2: null,
    manualValueUnit2Id: null
  };
}

export function makeDelays(): ContainsDelays {
  return {
    timerOnDelay: null,
    timerOffDelay: null
  };
}

export function convertArrayToOptions<T extends string>(arr?: readonly T[]): DropdownOptions<T> {
  if(isNotNullOrUndefined(arr))
    return [];
  return arr!.map(v => ({value: v, label: v}));
}

export function makeUser(): User {
  return {
    id: 0,
    email: '',
    archivedAt: null,
    firstName: '',
    lastName: '',
    mobileNumber: '',
    roles: [],
    countryName: '',
    timeZone: ''
  };
}
export function makeUserPreferences(user: User): UserPreferences {
  return {
    firstName: user.firstName,
    lastName: user.lastName,
    mobileNumber: user.mobileNumber,
    countryName: user.countryName,
    timeZone: user.timeZone
  };
}

export function makeUserAlertPreference(userId: number): UserAlertPreference {
  return {
    id: 0,
    startTime: '',
    endTime: '23:59',
    ...makeAlertScheduleWeekDays(),
    assetTripAndAlarm: 'Email',
    assetWarning: 'Email',
    maintenanceAlert: 'Email',
    systemWarning: 'Email',
    systemTripAndAlarm: 'Email',
    siteId: 0,
    userId: userId
  };
}


export function makeControllerType(): ControllerType {
  return {
    id: 0,
    name: '',
    description: '',
    protocolAdapterId: null,
    connectionKitId: 0,
    baudRate: '',
    manufacturerIds: [],
    assetGroupIds: [],
    thumbnailImageFileName: null,
    thumbnailImageThumbnailFileName: null
  };
}

export function makeControllerTypeRevision(controllerTypeId: number): ControllerTypeRevision {
  return {
    id: 0,
    name: '',
    controllerTypeId: controllerTypeId
  };
}
export function makeConnectionKit(): ConnectionKit {
  return {
    id: 0,
    name: '',
    description: ''
  };
}

export function makeProtocolAdapter(): ProtocolAdapter {
  return {
    id: 0,
    name: '',
    description: ''
  };
}

