import type { ModalState } from '../../portal/hooks/useModal';
import React, { useState } from 'react';
import { GenericModal } from '../../portal/utils/GenericModal/GenericModal';
import { ModalHoc } from '../../portal/utils/Modal/ModalHoc';
import { Alert, Button, Form } from 'react-bootstrap';
import { type OrgLevel, saveNote } from '../../api/userDashboardApi';
import { useMutation } from '@tanstack/react-query';
import { LoadingButton } from '../util/widgets/LoadingButton/LoadingButton';
import { handleAxiosError } from '../../common/util/http';
type NotesEditorModalParamType =  OrgLevel;
export type NotesEditorModalParam = { type: NotesEditorModalParamType; id: number; notes: string|null };

type NotesEditorModalProps = {
  state: ModalState<NotesEditorModalParam, undefined>;
};

export const NotesEditorModal =  ModalHoc(function NotesEditorModal({state}: NotesEditorModalProps) {
  const [notes, setNotes] = useState(state.param!.notes ?? '');

  const {mutateAsync, error, isPending} = useMutation({
    mutationFn: () => saveNote(state.param!.type, state.param!.id,  notes),
  });

  return (
    <GenericModal label="Edit Notes" state={state}>
      <Form.Control as="textarea" rows={3} value={notes} onChange={e => setNotes(e.currentTarget.value)} />
      {error && <Alert variant="danger">{handleAxiosError(error)}</Alert>}
      <div className="d-flex flex-row justify-content-end gap-2">
        <LoadingButton
          label='Submit'
          loading={isPending}
          className='mt-2 me-2 ms-auto mb-3 btn btn-small btn-block ml-auto text-white'
          onClick={async () => {
            await mutateAsync();
            window.location.reload();
            state.close();
          }}
        />
        <Button variant={'primary'} disabled={isPending} className='mt-2 mb-3 btn btn-small btn-block ml-auto text-white' onClick={() => state.close()}>
          Cancel
        </Button>
      </div>
    </GenericModal>
  );
});
