import s from './Panel.module.scss';
import React, { type CSSProperties, type ReactNode } from 'react';
import { combineClasses } from 'src/util';
import { type IconProp } from '@fortawesome/fontawesome-svg-core';
import clsx from 'clsx';

type Props = {
  children?: ReactNode;
  headerRight?: ReactNode;
  headerLeft?: ReactNode;
  className?: string;
  bodyClassName?: string;
  title?: ReactNode;
  darker?: boolean;
  backLink?: string;
  minHeight?: number;
  style?: CSSProperties;
};

export type IconButtonProps = { icon: IconProp; to: string; newTab?: boolean; tooltip: string };

export function Panel(props: Props) {
  const { children, headerRight, title, className, darker, minHeight, bodyClassName } = props;
  return (
    <div style={props.style} className={combineClasses(s['panel'], className, darker && s['darker'])}>
      <div className={combineClasses(s['header'], title && s['title'])}>
        {title}
        {!title && <>
          <div className={s['header-left']}>
            {props.headerLeft}
          </div>
          <div className={s['header-right']}>
            {headerRight}
          </div>
        </>}
      </div>
      <div className={clsx(s['panel-body'], bodyClassName)} style={minHeight ? { minHeight: minHeight } : undefined}>
        {children}
      </div>
    </div>
  );
}
