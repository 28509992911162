import { type AxiosInstance } from 'axios';
import { createRmxAxios, createStandardAxios } from '../../utils/ajax';
import {
  type Activity,
  type ActivityExpense,
  type ActivityExpenseResponse,
  type ActivityResponse,
  type ActivityTime,
  type ActivityTimeResponse,
  type AddInteractionRequest,
  type AssetWithDetailsResponse,
  type ContactInfo,
  type CreateActivityExpenseRequest,
  type UpsertQuoteRequest,
  type Flag,
  type FlagClearRequest,
  type FlagCreateRequest,
  type FlagResponse,
  type GetActivityDebriefResponse,
  type GetAssignedToResponse,
  type GetContactsRequest,
  type GetContactsResponse,
  type GetCustomerAndSiteAssetsRequest,
  type ItemInventory,
  type ItemInventoryResponse,
  type Invoice,
  type InvoiceResponse,
  type MyPartsResponse,
  type MyTime,
  type MyTimeResponse,
  type Part,
  type PartPickerRequest,
  type PartResponse,
  type Quote,
  type QuoteResponse,
  type SampleResponse,
  type TimeStatField,
  type UpdateActivityDebriefRequest,
  type UserOnboardingInteraction,
  type ActivityContactInteraction,
  type QuotePart
} from '../Service.types';
import { parseAspNetDateTime } from '../../utils';
import { format, parse, parseISO } from 'date-fns';
import {
  type Customer,
  type FileMedia,
  type GetActivitiesRequest,
  type GetFlagsRequest,
  type GetLastLoadedReportRequest,
  type GetMyPartsRequest,
  type GetMyTimeRequest,
  type GetPartsRequest,
  type GetQuoteRequest,
  type GetSampleRequest,
  type IRmxServiceApi
} from './index';
import { type DataTableResponse } from '../../shared-compat/DataTable/hooks';
import type { TimeSpan } from '../../utils/timespan';
import { type BlobWithName } from 'src/portal/utils/BlobWithName';

function parsePartsResponses(resp: DataTableResponse<PartResponse>): DataTableResponse<Part> {
  return {
    ...resp,
    data: resp.data.map((part) => ({
      ...part,
      orderedOn: parseAspNetDateTime(part.orderedOn),
      // year month day time format
      expectedOn: part.expectedOn ? parseISO(part.expectedOn) : null,
      usedOn: part.usedOn ? parseISO(part.usedOn) : null
    }))
  };
}

function parsePartsByActivityIdResponses(resp: PartResponse[]): Part[] {
  return resp.map((part) => ({
    ...part,
    orderedOn: part.orderedOn ? parseISO(part.orderedOn) : null,
    // year month day time format
    expectedOn: part.expectedOn ? parseISO(part.expectedOn) : null,
    usedOn: parseAspNetDateTime(part.usedOn)
  }));
}

function parseServiceResponses(resp: DataTableResponse<ActivityResponse>): DataTableResponse<Activity> {
  return {
    ...resp,
    data: resp.data.map((service) => ({
      ...service,
      startDate: parseISO(service.startDate + 'T00:00:00'),
      createdAt: parseISO(service.createdAt),
      onSiteTime: parse(service.onSiteTime, 'HH:mm:ss', new Date())
      // ClosedOn: parseAspNetDateTime(service.ClosedOn)
    }))
  };
}

function parseQuoteResponses(quotes: DataTableResponse<QuoteResponse>): DataTableResponse<Quote> {
  return {
    ...quotes,
    data: quotes.data.map((quote) => ({
      ...quote,
      createdAt: parseISO(quote.createdAt),
      expiresAt: parseISO(quote.expiresAt)
    }))
  };
}

function parseInvoiceResponses(invoices: InvoiceResponse[]): Invoice[] {
  return invoices.map((invoice) => ({
    ...invoice,
    CreatedOn: parseISO(invoice.CreatedOn + 'T00:00:00'),
    DueOn: parseISO(invoice.DueOn + 'T00:00:00')
  }));
}

const parseTimeOnlyString = (time: string) => {
  const [hours, minutes, seconds] = time.split(':').map(Number);
  return new Date(0, 0, 0, hours, minutes, seconds);
};

function parseActivityTime(activityTime: ActivityTimeResponse[]): ActivityTime[] {
  return activityTime.map((time) => ({
    ...time,
    startTime: parseTimeOnlyString(time.startTime),
    endTime: parseTimeOnlyString(time.endTime),
    entryDate: parseISO(time.entryDate),
    totalTime: parseTimeOnlyString(time.totalTime)
  }));
}

export function getPortalServiceApi(): IRmxServiceApi {
  return new PortalServiceApi(createStandardAxios());
}

export interface IActivityUpload {
  id?: string;
  activityId?: string;
  title: string;
  noUploadReason?: string;
  fileNames?: string[];
}

export interface RequiredActivityUpload {
  dealerId: number;
  title: string;
  activityType: string;
}

class PortalServiceApi implements IRmxServiceApi {
  private rmxServiceAxios: AxiosInstance = createRmxAxios();

  constructor(private api: AxiosInstance) {}

  async getCustomerByCrmId(crmId: string) {
    return (await this.rmxServiceAxios.get<Customer>(`/Customer/CrmId/${crmId}`)).data;
  }
  updateActivityContactNoResponse(activityId: string, noResponse: boolean): Promise<void> {
    return this.rmxServiceAxios.post(`/Activity/UpdateActivityContactNoResponse`, { activityId, confirmedNoResponse: noResponse });
  }

  async deleteQuote(quoteId: string) {
    return (await this.rmxServiceAxios.delete<void>(`/Quote/${quoteId}`)).data;
  }

  async getParts(r: GetPartsRequest) {
    return parsePartsResponses((await this.rmxServiceAxios.post<DataTableResponse<PartResponse>>('/ActivityPart/GetParts', r)).data);
  }

  async getPartsByActivityId(activityId: string) {
    return parsePartsByActivityIdResponses((await this.rmxServiceAxios.get<PartResponse[]>(`/ActivityPart/GetPartsByActivityId/${activityId}`)).data);
  }
  // async getQuotes(assetId?: number) {
  //   return parseQuoteResponses((await this.api.get<QuoteResponse[]>('/Uploads/TestData/Quotes.json?v=2')).data);
  // }

  async getInvoices(assetId?: number) {
    return parseInvoiceResponses((await this.api.get<InvoiceResponse[]>('/Uploads/TestData/Invoices.json?v=2')).data);
  }

  async getSamples(r: GetSampleRequest) {
    const resp = (await this.rmxServiceAxios.post<DataTableResponse<SampleResponse>>('/Sample/', r)).data;
    return {
      ...resp,
      data: resp.data.map((sample) => ({
        ...sample,
        sampleDate: parse(sample.sampleDate, 'yyyy-MM-dd', new Date())
      }))
    };
  }

  async getFlags(req: GetFlagsRequest): Promise<DataTableResponse<Flag>> {
    const resp = (await this.rmxServiceAxios.post<DataTableResponse<FlagResponse>>('/Flag', req)).data;
    return {
      ...resp,
      data: resp.data.map((flag) => ({
        ...flag,
        createdAt: parseISO(flag.createdAt),
        respondedAt: flag.respondedAt ? parseISO(flag.respondedAt) : null,
        clearedAt: flag.clearedAt ? parseISO(flag.clearedAt) : null,
        claimedAt: flag.claimedAt ? parseISO(flag.claimedAt) : null
      }))
    };
  }

  async getCustomerAndSiteAssets(request: GetCustomerAndSiteAssetsRequest): Promise<AssetWithDetailsResponse[]> {
    return (await this.rmxServiceAxios.post<AssetWithDetailsResponse[]>(`/Asset/GetCustomerAndSiteAssets/`, request)).data;
  }

  async isAssetMonitored(assetId: number): Promise<boolean> {
    return (await this.rmxServiceAxios.get<boolean>(`/Asset/${assetId}/IsMonitored`)).data;
  }

  async getAssignedToOptions(linkedCrmId: number): Promise<GetAssignedToResponse[]> {
    return (await this.rmxServiceAxios.get<GetAssignedToResponse[]>(`/Activity/GetAssignedToOptions/${linkedCrmId}`)).data;
  }

  // Activity Get Methods
  async getActivities(r: GetActivitiesRequest) {
    return parseServiceResponses((await this.rmxServiceAxios.post<DataTableResponse<ActivityResponse>>('/Activity', r)).data);
  }
  async getMyActivities(r: GetActivitiesRequest) {
    return parseServiceResponses((await this.rmxServiceAxios.post<DataTableResponse<ActivityResponse>>('/Activity/Me', r)).data);
  }

  // Activity Update Methods
  async updateActivityWorkOrderType(activityId: string | number, workOrderType: string) {
    // WorkOrderType is actually ActivityType in the CRM
    return (await this.rmxServiceAxios.post<void>(`/Activity/UpdateActivityWorkOrderType/${activityId}`, { activityId, workOrderType })).data;
  }
  async updateActivityDescription(activityId: string | number, description: string) {
    return (await this.rmxServiceAxios.post<void>(`/Activity/UpdateActivityDescription/${activityId}`, { activityId, description })).data;
  }

  async updateActivityAvailability(activityId: string | number, availability: string) {
    return (await this.rmxServiceAxios.post<void>(`/Activity/UpdateActivityAvailability/${activityId}`, { activityId, availability })).data;
  }

  async updateActivityAssignedTo(activityId: string | number, assignedToId: string) {
    return (await this.rmxServiceAxios.post<void>(`/Activity/UpdateActivityAssignedTo/${activityId}`, { assignedToId: assignedToId })).data;
  }

  async updateActivityStartDate(activityId: string | number, startDate: Date) {
    return (await this.rmxServiceAxios.post<void>(`/Activity/UpdateActivityStartDate/${activityId}`, { activityId, startDate })).data;
  }

  async updateActivityOnSiteTime(activityId: string | number, time: Date) {
    let onSiteTime = time.toTimeString();
    onSiteTime = onSiteTime.split(' ')[0] as string;
    return (await this.rmxServiceAxios.post<void>(`/Activity/UpdateActivityOnSiteTime/${activityId}`, { activityId, onSiteTime })).data;
  }

  async updateActivityEstimatedDuration(activityId: string | number, estimatedDuration: TimeSpan) {
    return (await this.rmxServiceAxios.post<void>(`/Activity/UpdateActivityEstimatedDuration/${activityId}`, { activityId, estimatedDuration })).data;
  }

  async updateActivityAssetId(activityId: string | number, assetId: number) {
    return (await this.rmxServiceAxios.post<void>(`/Activity/UpdateActivityAssetId/${activityId}`, { assetId })).data;
  }

  // Post Methods
  async postActivityQuote(activityId: string | number, quoteId: number) {
    return (await this.api.post<void>(`/RmxService/ActivityQuote`, { activityId, quoteId })).data;
  }

  async postActivityExpense(activityId: string | number, expenseId: number) {
    return (await this.api.post<void>(`/RmxService/ActivityExpense`, { activityId, expenseId })).data;
  }

  async postActivityUpload(activityId: string | number, file: File) {
    const formData = new FormData();
    formData.append('activityId', activityId.toString());
    formData.append('file', file);
    return (await this.api.post<void>(`/RmxService/ActivityUpload`, formData)).data;
  }

  async postActivityTime(startTime: Date, endTime: Date, type: string, totalTime: Date, activityId?: string | number, reason?: string, entryDate?: Date) {
    const totalTimeString = totalTime.toTimeString();
    const totalTimeSpan = totalTimeString.split(' ')[0] as string;

    const startTimeString = startTime.toTimeString();
    const endTimeString = endTime.toTimeString();
    const startTimeOnly = startTimeString.split(' ')[0] as string;
    const endTimeOnly = endTimeString.split(' ')[0] as string;

    const activityTimeModel = {
      activityId: activityId,
      reason: reason,
      startTime: startTimeOnly,
      endTime: endTimeOnly,
      type: type,
      total: totalTimeSpan,
      entryDate: format(entryDate ?? new Date(), 'yyyy-MM-dd') // doing this is neccessary for c# to accept the date as a valid Date only
    };

    return (await this.rmxServiceAxios.post<void>(`/ActivityTime`, activityTimeModel)).data;
  }

  async postActivitySample(activityId: string | number, sampleId: number) {
    return (await this.api.post<void>(`/RmxService/ActivitySample`, { activityId, sampleId })).data;
  }

  // Delete Methods
  async deleteActivityTime(activityId: string | number, startTime: Date, entryDate: Date) {
    console.log(`startTime: ${startTime}, entryDate: ${entryDate}`);
    const formattedDate = format(entryDate, 'MM dd yyyy');
    const formattedTime = format(startTime, 'hh:mm aa');
    return (await this.rmxServiceAxios.delete<void>(`/ActivityTime/${activityId}/${formattedTime}/${formattedDate}`)).data;
  }

  // Part
  async pickPart(req: PartPickerRequest) {
    return (await this.rmxServiceAxios.post<void>(`/ActivityPart/PickPart`, req)).data;
  }

  async usedPart(req: PartPickerRequest[]) {
    return (await this.rmxServiceAxios.post<void>(`/ActivityPart/UsedPart`, req)).data;
  }

  async getToteParts(userId: number): Promise<ItemInventoryResponse[]> {
    return (await this.rmxServiceAxios.get<ItemInventoryResponse[]>(`/ItemInventory/Tote/${userId}`)).data;
  }

  async addTotePartToActivity(activityId: string, ccn: string, selectedQuantity: number): Promise<void> {
    return (await this.rmxServiceAxios.post<void>('/ItemInventory/AddTotePartToActivity', { activityId, ccn, quantity: selectedQuantity })).data;
  }

  async myParts(req: GetMyPartsRequest) {
    return (await this.rmxServiceAxios.post<DataTableResponse<MyPartsResponse>>(`/ItemInventory`, req)).data;
  }

  async getPartLibrary(r: GetPartsRequest) {
    const data = (await this.rmxServiceAxios.post<DataTableResponse<ItemInventoryResponse>>('/Item', r)).data;

    return {
      ...data,
      data: data.data.map<ItemInventory>((part) => ({
        ...part,
        lastUsedOn: part.lastUsedOn !== null ? parseISO(part.lastUsedOn) : null
      }))
    };
  }

  async getActivityTime(activityId: string): Promise<ActivityTime[]> {
    return parseActivityTime((await this.rmxServiceAxios.get<ActivityTimeResponse[]>(`/ActivityTime/${activityId}`)).data);
  }
  async getLastLoadedReport(req: GetLastLoadedReportRequest): Promise<Blob> {
    return (await this.rmxServiceAxios.get<Blob>(`/AssetReport/generate-image/${req.assetId}`, { params: { timeZone: req.timeZone }, responseType: 'blob' }))
      .data;
  }

  async isSlamSubmitted(activityId: string): Promise<boolean> {
    const slamSubmission: string = (await this.rmxServiceAxios.get<string>(`/FormSubmission/GetSlamSubmission/${activityId}`)).data;
    const slamSubmissionData = slamSubmission as unknown as { submit: boolean };
    return slamSubmissionData.submit;
  }

  async upsertActivityUploads(activityUpload: IActivityUpload, files: File[] | BlobWithName[]) {
    const formData = new FormData();
    if (files) {
      files.forEach((file) => formData.append('files', file, file.name));
    }
    formData.append('activityUpload', JSON.stringify(activityUpload));
    const config = {
      headers: { 'Content-Type': 'multipart/form-data' }
    };
    const createActivityUploadsUrl = new URL(`${this.rmxServiceAxios.defaults.baseURL}/ActivityUpload`);
    await this.rmxServiceAxios.post(createActivityUploadsUrl.toString(), formData, config);
  }

  async getActivityUploads(activityId: string): Promise<IActivityUpload[]> {
    return (await this.rmxServiceAxios.get<IActivityUpload[]>(`/ActivityUpload/${activityId}`)).data;
  }

  async getRequiredActivityUploads(dealerId: number, activityType: string) {
    return (await this.rmxServiceAxios.get<RequiredActivityUpload[]>(`/ActivityUpload/Required/${dealerId}/${activityType}`)).data;
  }

  async deleteActivityUpload(activityUploadId: string): Promise<void> {
    await this.rmxServiceAxios.delete(`/ActivityUpload/${activityUploadId}`);
  }

  async createFlag(flag: FlagCreateRequest): Promise<void> {
    await this.rmxServiceAxios.post('/Flag/Create', flag);
  }
  async claimFlag(flagId: number): Promise<void> {
    await this.rmxServiceAxios.post(`/Flag/Claim/${flagId}`);
  }

  async clearFlag(flag: FlagClearRequest): Promise<void> {
    await this.rmxServiceAxios.post(`/Flag/Clear`, flag);
  }

  async createActivityExpense(req: CreateActivityExpenseRequest, files: File[]): Promise<void> {
    const formData = new FormData();
    if (files) {
      files.forEach((file) => formData.append('files', file));
    }
    formData.append('activityExpense', JSON.stringify(req));
    const config = {
      headers: { 'Content-Type': 'multipart/form-data' }
    };
    await this.rmxServiceAxios.post('/ActivityExpense', formData, config);
  }

  async getActivityExpenses(activityId?: string): Promise<ActivityExpense[]> {
    const expenses = await this.rmxServiceAxios.get<ActivityExpenseResponse[]>(`/ActivityExpense/${activityId}`);
    return expenses.data.map((expense) => ({
      ...expense,
      createdAt: expense.createdAt ? parseISO(expense.createdAt) : null
    }));
  }
  async getQuotes(req: GetQuoteRequest): Promise<DataTableResponse<Quote>> {
    const resp = (await this.rmxServiceAxios.post<DataTableResponse<QuoteResponse>>('/Quote', req)).data;
    return parseQuoteResponses(resp);
  }

  async getQuotesByActivityId(activityId: string): Promise<Quote[]> {
    const quotes = await this.rmxServiceAxios.get<QuoteResponse[]>(`/Quote/${activityId}`);
    return quotes.data.map((quote) => ({
      ...quote,
      createdAt: parseISO(quote.createdAt),
      expiresAt: parseISO(quote.expiresAt)
    }));
  }
  async createQuote(quote: UpsertQuoteRequest, files?: File[]): Promise<void> {
    const formData = new FormData();
    if (files) {
      files.forEach((file) => formData.append('file', file));
    }
    formData.append('activityQuote', JSON.stringify(quote));
    const config = {
      headers: { 'Content-Type': 'multipart/form-data' }
    };
    await this.rmxServiceAxios.post('/Quote/Create', formData, config);
  }

  async getActivityDebrief(activityId: string): Promise<GetActivityDebriefResponse> {
    return await this.rmxServiceAxios.get(`/Activity/Debrief/${activityId}`);
  }

  async updateActivityScope(activityId: string, scope: string, signal: AbortSignal): Promise<void> {
    await this.rmxServiceAxios.post<void>(
      `/Activity/Debrief/Scope/${activityId}`,
      { scope: scope },
      {
        signal
      }
    );
  }
  async updateActivityDebrief(request: UpdateActivityDebriefRequest): Promise<void> {
    await this.rmxServiceAxios.post(`/Activity/Debrief/${request.activityId}`, request);
  }

  async getMyTime(req: GetMyTimeRequest): Promise<DataTableResponse<MyTime>> {
    const resp = (await this.rmxServiceAxios.post<DataTableResponse<MyTimeResponse>>('/ActivityTime/Me', req)).data;
    return {
      ...resp,
      data: resp.data.map((time) => ({
        ...time,
        startTime: parseTimeOnlyString(time.startTime),
        endTime: parseTimeOnlyString(time.endTime),
        entryDate: parseISO(time.entryDate),
        totalTime: parseTimeOnlyString(time.totalTime)
      }))
    };
  }

  async getTimeStats(req: { startDate?: Date; endDate?: Date }): Promise<TimeStatField> {
    const resp = (await this.rmxServiceAxios.post<TimeStatField>('/ActivityTime/TimeSummary', req)).data;
    return {
      ...resp,
      travelRatio: isNaN(Math.round((resp.travelRatio ?? 0) * 100)) ? 0 : Math.round(resp.travelRatio * 100),
      revPerHour: resp.revPerHour ?? 0
    };
  }

  async deleteNonActivityTime(timeCrmId: string): Promise<void> {
    await this.rmxServiceAxios.delete(`/ActivityTime/${timeCrmId}`);
  }

  async getCustomerContacts(customerId: number): Promise<ContactInfo[]> {
    return (await this.rmxServiceAxios.get<ContactInfo[]>(`/Contact/Customer/${customerId}`)).data;
  }

  async upsertContact(customerId: number, contact: ContactInfo): Promise<void> {
    await this.rmxServiceAxios.post(`/Contact/UpsertContact`, { customerId, ...contact });
  }

  async updateActivityPrimaryContact(activityId: string, contactId: number): Promise<void> {
    await this.rmxServiceAxios.post(`/Contact/UpdateActivityContact`, {
      contactId: contactId,
      activityId: activityId
    });
  }

  async getActivityContact(activityId: string): Promise<ContactInfo> {
    return (await this.rmxServiceAxios.get<ContactInfo>(`/Contact/Activity/${activityId}`)).data;
  }

  // Onboarding Guide
  async addOnboardingInteraction(request: AddInteractionRequest): Promise<void> {
    await this.rmxServiceAxios.post('/OnboardingGuide/AddInteraction', {
      currentStep: request.step,
      onboardingGuideId: request.guideId,
      hasCompleted: request.isCompleted
    });
  }

  async getUserOnboardGuideInfo(): Promise<UserOnboardingInteraction[]> {
    return (await this.rmxServiceAxios.get<UserOnboardingInteraction[]>('/OnboardingGuide')).data;
  }

  async getContacts(req: GetContactsRequest): Promise<GetContactsResponse> {
    return (await this.rmxServiceAxios.post<GetContactsResponse>(`/Contact`, req)).data;
  }

  // async getWorkOrderContacts(workOrderId: number): Promise<ContactInfo[]> {
  //   return (await this.rmxServiceAxios.get<ContactInfo[]>(`/Contact/WorkOrder/${workOrderId}`)).data;
  // }

  async addContactToWorkOrder(workOrderId: number, contactId: number): Promise<void> {
    return await this.rmxServiceAxios.post(`/Contact/AddToWorkOrder`, { workOrderId, contactId });
  }

  removeContactFromWorkOrder(workOrderContactId: number): Promise<void> {
    return this.rmxServiceAxios.post(`/Contact/RemoveFromWorkOrder`, { workOrderContactId });
  }

  updateActivityConfirmAsset(activityId: string, assetId: number): Promise<void> {
    return this.rmxServiceAxios.post(`/Activity/UpdateActivityConfirmAsset/${activityId}`, { assetId });
  }

  updateActivityAssetHours(activityId: string, hours: number, unknownHourReason: string | null, signal: AbortSignal): Promise<void> {
    return this.rmxServiceAxios.post(
      `/Activity/UpdateActivityAssetHours/${activityId}`,
      {
        assetHours: hours,
        unknownAssetHourReason: unknownHourReason
      },
      {
        signal
      }
    );
  }
  // ('Bereave', 'Jury', 'Vacation', 'Training', 'Flex')
  // time gets put as an activity so that it appears in the tech calendar table

  async confirmContactInteraction(activityId: string, contactId: number): Promise<void> {
    return await this.rmxServiceAxios.post(`/Activity/ConfirmContact`, { activityId, contactId });
  }
  async deleteContactInteraction(activityId: string, contactId: number): Promise<void> {
    return await this.rmxServiceAxios.post(`/Activity/DeleteConfirmContact`, { activityId, contactId });
  }
  async getConfirmedContacts(activityId: string): Promise<ActivityContactInteraction[]> {
    return (await this.rmxServiceAxios.get<ActivityContactInteraction[]>(`/Activity/ConfirmedContacts/${activityId}`)).data;
  }

  async updateDebriefWithCommMethod(contactInteractionId: number, communicationMethod: string): Promise<void> {
    return await this.rmxServiceAxios.post(`/ActivityContactInteraction/UpdateCommunicationMethod`, { contactInteractionId, communicationMethod });
  }

  async debriefWithContactInteraction(activityId: string, contactId: number): Promise<void> {
    return await this.rmxServiceAxios.post(`/Activity/DebriefWith`, { activityId, contactId });
  }

  async deleteDebriefWithContactInteraction(contactInteractionId: number): Promise<void> {
    return await this.rmxServiceAxios.post(`/Activity/DeleteDebriefWith`, { contactInteractionId });
  }

  async getDebriefWithContacts(activityId: string): Promise<ActivityContactInteraction[]> {
    return (await this.rmxServiceAxios.get<ActivityContactInteraction[]>(`/Activity/DebriefWith/${activityId}`)).data;
  }

  // Quote Autosave functionality
  async getUnsubmittedQuote(activityId: string): Promise<Quote | null> {
    return (await this.rmxServiceAxios.get<Quote>(`/Quote/Unsubmitted/${activityId}`)).data;
  }

  async deleteQuoteFile(quoteId: string, fileId: string) {
    return (await this.rmxServiceAxios.delete<void>(`/Quote/DeleteFile/${quoteId}/${fileId}`)).data;
  }

  async createAndSyncQuoteFile(quoteId: string, file: BlobWithName) {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('quoteUpload', JSON.stringify({ quoteId: quoteId }));
    const config = {
      headers: { 'Content-Type': 'multipart/form-data' }
    };
    return (await this.rmxServiceAxios.post<void>(`/Quote/CreateAndSyncFile`, formData, config)).data;
  }

  async updateQuoteTitle(params: { activityId: string; quoteId: string | null; title: string }, signal: AbortSignal): Promise<void> {
    return await this.rmxServiceAxios.post(`/Quote/UpdateTitle`, params, { signal });
  }
  async updateQuoteNumberOfTechs(params: { activityId: string; quoteId: string | null; numberOfTechs: number | null }, signal: AbortSignal): Promise<void> {
    return await this.rmxServiceAxios.post(`/Quote/UpdateNumberOfTechs`, params, { signal });
  }
  async updateQuoteEstimatedDuration(params: { activityId: string; quoteId: string | null; estimatedDuration: string }): Promise<void> {
    return await this.rmxServiceAxios.post(`/Quote/UpdateEstimatedDuration`, params);
  }
  async updateQuoteConfirmAsset(params: { activityId: string; quoteId: string | null; confirmAsset: number }): Promise<void> {
    return await this.rmxServiceAxios.post(`/Quote/UpdateConfirmAsset`, params);
  }
  async updateQuoteIncludedParts(params: { activityId: string; quoteId: string | null; includedParts: QuotePart[] }): Promise<void> {
    return await this.rmxServiceAxios.post(`/Quote/UpdateIncludedParts`, params);
  }
  async updateQuoteUploads(params: { activityId: string; quoteId: string | null }, file: Blob): Promise<void> {
    const formData = new FormData();
    if (file) {
      formData.append('files', file);
    }

    formData.append('quoteUpload', JSON.stringify(params));
    const config = {
      headers: { 'Content-Type': 'multipart/form-data' }
    };
    return await this.rmxServiceAxios.post(`/Quote/UpdateUploads`, formData, config);
  }

  async updateQuotePrivateNotes(params: { activityId: string; quoteId: string; privateNotes: string }, signal: AbortSignal): Promise<void> {
    return await this.rmxServiceAxios.post(`/Quote/UpdatePrivateNotes`, params, { signal });
  }

  async syncedUser(): Promise<boolean> {
    return (await this.rmxServiceAxios.get<boolean>(`/User/SyncedUser`)).data;
  }

  async getFiles(parentResourceType: string, parentResourceId: string | null): Promise<FileMedia> {
    return (await this.rmxServiceAxios.post<FileMedia>('/Files/GetParentResourceFiles', { parentResourceType, parentResourceId })).data;
  }
}
