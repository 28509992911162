/* eslint-disable @typescript-eslint/naming-convention */
import {
  RmxBoxPaths,
  RmxBoxPortSettingsPaths,
  RmxBoxSourcePaths
} from '../pages/Configuration/GlobalConfiguration/pages/rmx-box/rmxBoxPaths';
import {type AlertConversionType} from '../common/redux/entities/conversions/alertConversion';
import {useCurrentUrl} from '../pages/Dashboard/DashboardRoutePaths';
import {type RouteObject, useParams} from 'react-router-dom';
import {type AppRouteObject} from './util';
import {type ReactNode} from 'react';

export interface RoutePath {
  path: string;
  component: any;
  public?: boolean;
  exact?: boolean;
}

enum HomePaths {
  home = '/'
}

enum MiscellaneousPaths {
  storage = '/Storage',
  images = '/images'
}

enum DashboardPaths {
  dashboard = '/dashboard'
}

enum AuthenticationPaths {
  login = '/login',
  logout = '/logout',
  forgotPassword = '/forgot-password',
  resetPassword = '/reset-password'
}

export function applyBasePath(basePath: string, pathFn: (basePath: string) => string) {
  return pathFn(basePath);
}

export function applyBasePaths(basePath: string, ...pathsFns: Array<(basePath: string) => string>) {
  return pathsFns.map(fn => applyBasePath(basePath, fn));
}

enum ConfigurationPaths {
  globalConfiguration = '/configuration/global',
  companySelection = '/configuration/company',
  companyConfiguration = '/configuration/company/:companyId',
  userManagement = '/configuration/user-management'
}
const UserManagementBasePath =  ConfigurationPaths.userManagement;
const UserManagementPaths = {
  New: UserManagementBasePath + '/user/new',
  Edit: UserManagementBasePath + '/user/edit/:userId',
  View: UserManagementBasePath + '/user/view/:userId'
};

const AssetTemplatePaths = {
  New: ConfigurationPaths.globalConfiguration + '/asset-template/new',
  Edit: ConfigurationPaths.globalConfiguration + '/asset-template/edit/:assetTemplateId',
  View: ConfigurationPaths.globalConfiguration + '/asset-template/view/:assetTemplateId',
  NewAssetModule: ConfigurationPaths.globalConfiguration + '/asset-template/:assetTemplateId/:parentRevisionId/new',
  EditAssetModule: ConfigurationPaths.globalConfiguration + '/asset-template/:assetTemplateId/:parentRevisionId/edit/:moduleId',
  ViewAssetModule: ConfigurationPaths.globalConfiguration + '/asset-template/:assetTemplateId/:parentRevisionId/view/:moduleId'
};
export const getAssetTemplateView = (id: number) => AssetTemplatePaths.View.replace(':assetTemplateId', id.toString());

const SpTemplatePaths = {
  New: ConfigurationPaths.globalConfiguration + '/sp-template/new',
  Edit: ConfigurationPaths.globalConfiguration + '/sp-template/edit/:id',
  View: ConfigurationPaths.globalConfiguration + '/sp-template/view/:id'
};
export const getSpTemplateView = (id: number) => SpTemplatePaths.View.replace(':id', id.toString());

const GlobalDealerConfigurationBasePath =  ConfigurationPaths.globalConfiguration + '/dealer/:dealerId';
const GlobalDealerConfigurationPaths = {
  NewDealer: ConfigurationPaths.globalConfiguration + '/dealer/new',
  EditDealer: ConfigurationPaths.globalConfiguration + '/dealer/edit/:dealerId',
  ViewDealer: ConfigurationPaths.globalConfiguration + '/dealer/view/:dealerId'
};

const ManufacturerBasePath =  ConfigurationPaths.globalConfiguration + '/manufacturer';
const ManufacturerPaths = {
  New: ManufacturerBasePath + '/new',
  Edit: ManufacturerBasePath + '/edit/:manufacturerId',
  View: ManufacturerBasePath + '/dealer/view/:manufacturerId'
};

const GlobalCompanyConfigurationPaths = {
  NewCompany: GlobalDealerConfigurationBasePath + '/company/new',
  EditCompany: GlobalDealerConfigurationBasePath + '/company/edit/:companyId',
  ViewCompany: GlobalDealerConfigurationBasePath + '/company/view/:companyId'
};

const DataPointPaths = {
  NewDataPoint: `${ConfigurationPaths.globalConfiguration}/data-point/:dataPointType/new`,
  ViewDataPoint: `${ConfigurationPaths.globalConfiguration}/data-point/:dataPointType/view/:dataPointId`,
  EditDataPoint: `${ConfigurationPaths.globalConfiguration}/data-point/:dataPointType/edit/:dataPointId`,
  ViewUnitGroupDataPoint: `${ConfigurationPaths.globalConfiguration}/data-point/:dataPointType/:unitGroupType/view/:dataPointId`,
  EditUnitGroupDataPoint: `${ConfigurationPaths.globalConfiguration}/data-point/:dataPointType/:unitGroupType/edit/:dataPointId`
};
const baseSitePath = ConfigurationPaths.companyConfiguration + '/site/:siteId';
const SiteConfigurationPaths = {
  New: ConfigurationPaths.companyConfiguration + '/site/new',
  View: ConfigurationPaths.companyConfiguration + '/site/view/:siteId',
  Edit: ConfigurationPaths.companyConfiguration + '/site/edit/:siteId'
};

const InstallationConfigurationPaths = {
  New: baseSitePath + '/installation/new',
  View: baseSitePath + '/installation/view/:installationId',
  Edit: baseSitePath + '/installation/edit/:installationId'
};

const AssetRevisionPaths = {
  New: ConfigurationPaths.companyConfiguration + '/installation/:installationId/installation-revision/:installationRevisionId/asset-revision/new',
  NewEmbedded: `${ConfigurationPaths.companyConfiguration}/installation/:installationId/installation-revision/:installationRevisionId/asset-revision-parent/:parentId/new-embedded`,
  View: ConfigurationPaths.companyConfiguration +
    '/installation/:installationId/installation-revision/:installationRevisionId/asset-revision/:id/view',
  Edit: ConfigurationPaths.companyConfiguration +
    '/installation/:installationId/installation-revision/:installationRevisionId/asset-revision/:id/edit'
};

const SpRevisionPaths = {
  New: ConfigurationPaths.companyConfiguration + '/installation/:installationId/installation-revision/:installationRevisionId/sp-revision/new',
  View: ConfigurationPaths.companyConfiguration +
    '/installation/:installationId/installation-revision/:installationRevisionId/sp-revision/:id/view',
  Edit: ConfigurationPaths.companyConfiguration +
    '/installation/:installationId/installation-revision/:installationRevisionId/sp-revision/:id/edit'
};

export const manufacturerOperationalStatusLabelsPaths = {
  New: `${ConfigurationPaths.globalConfiguration}/manufacturer-operation-status-label/new`,
  NewWithManufacturer: `${ConfigurationPaths.globalConfiguration}/manufacturer-operation-status-label/:manufacturerId/new`,
  View: `${ConfigurationPaths.globalConfiguration}/manufacturer-operation-status-label/:manufacturerId/view/:assetGroupId`,
  Edit: `${ConfigurationPaths.globalConfiguration}/manufacturer-operation-status-label/:manufacturerId/edit/:assetGroupId`
};

export type UrlParam = number|string;

export function applyCompanyConfigUrl(
  url: string,
  companyId: UrlParam,
  installationId: UrlParam,
  installationRevisionId: UrlParam,
  assetRevisionId?: UrlParam
) {
  return url
    .replace(':companyId',  String(companyId))
    .replace(':installationId',  String(installationId))
    .replace(':installationRevisionId',  String(installationRevisionId))
    .replace(':id', String(assetRevisionId ?? ''));
}

export type CompanyRouteParams = {companyId: string};
export type SiteRouteParams = CompanyRouteParams & {siteId: string};

export type InstallationEntityRouteParams = CompanyRouteParams & {
  siteId?: string;
  installationId: string;
  installationRevisionId?: string;
};

export const applySiteUrl = (url: string, params: SiteRouteParams) => {
  const {siteId} = params;
  return applyCompanyUrl(url, params)
    .replace(':siteId',  String(siteId ?? ''));
};

export const applyCompanyUrl = (url: string, params: CompanyRouteParams) => {
  const {companyId} = params;
  return url
    .replace(':companyId',  String(companyId));
};

export const applyCompanyConfigUrlFromProps = (url: string, params: InstallationEntityRouteParams, id?: UrlParam) => {
  const {installationId, installationRevisionId, siteId} = params;
  return applyCompanyUrl(url, params)
    .replace(':siteId',  String(siteId ?? ''))
    .replace(':installationId',  String(installationId))
    .replace(':installationRevisionId',  String(installationRevisionId))
    .replace(':id', String(id ?? ''));
};

export interface ComponentPath {
  element?: ReactNode;
  lazy?: RouteObject['lazy'];
  paths: string[];
}

export function convertComponentPaths(...editorPaths: ComponentPath[]): AppRouteObject[] {
  return editorPaths.flatMap(editorPath => editorPath.paths.map<AppRouteObject>(path => ({
    path: path,
    element: editorPath.element,
    lazy: editorPath.lazy
  })));
}
const assetGroupBasePath = ConfigurationPaths.globalConfiguration + '/asset-group/:assetGroupId';
const AssetGroupPaths = {
  Edit: ConfigurationPaths.globalConfiguration + '/asset-group/edit/:assetGroupId',
  View: ConfigurationPaths.globalConfiguration + '/asset-group/view/:assetGroupId',
  New: ConfigurationPaths.globalConfiguration + '/asset-group/new'
};

const AssetTypePaths = {
  Edit: assetGroupBasePath + '/asset-type/edit/:assetTypeId',
  View: assetGroupBasePath + '/asset-type/view/:assetTypeId',
  New: assetGroupBasePath + '/asset-type/new'
};

const AlertTypePaths = {
  Edit: ConfigurationPaths.globalConfiguration + '/alert-type/edit/:alertTypeId',
  View: ConfigurationPaths.globalConfiguration + '/alert-type/view/:alertTypeId',
  New: ConfigurationPaths.globalConfiguration + '/alert-type/new'
};

const DpaAlertPaths = {
  Edit: ConfigurationPaths.globalConfiguration + '/dpa-alert/edit/:alertId',
  View: ConfigurationPaths.globalConfiguration + '/dpa-alert/view/:alertId',
  New: ConfigurationPaths.globalConfiguration + '/dpa-alert/new'
};

const NdpaAlertPaths = {
  Edit: ConfigurationPaths.globalConfiguration + '/ndpa-alert/edit/:alertId',
  View: ConfigurationPaths.globalConfiguration + '/ndpa-alert/view/:alertId',
  New: ConfigurationPaths.globalConfiguration + '/ndpa-alert/new'
};
const AlertCodeConversionTail = '/alert-code-conversion';
const AlertCodeConversionBasePath = ConfigurationPaths.globalConfiguration + AlertCodeConversionTail;
const AlertCodeConversionPaths = {
  Edit: AlertCodeConversionBasePath + '/edit/:id',
  View: AlertCodeConversionBasePath + '/view/:id',
  New: AlertCodeConversionBasePath + '/new'
};
const AlertOneToOneConversionTail = '/alert-one-to-one-conversion';
const AlertOneToOneConversionBasePath = ConfigurationPaths.globalConfiguration + AlertOneToOneConversionTail;
const AlertOneToOneConversionPaths = {
  Edit: AlertOneToOneConversionBasePath + '/edit/:id',
  View: AlertOneToOneConversionBasePath + '/view/:id',
  New: AlertOneToOneConversionBasePath + '/new'
};
const AlertBitByBitConversionTail = '/alert-bit-by-bit-conversion';
const AlertBitByBitConversionBasePath = ConfigurationPaths.globalConfiguration + AlertBitByBitConversionTail;
const AlertBitByBitConversionPaths = {
  Edit: AlertBitByBitConversionBasePath + '/edit/:id',
  View: AlertBitByBitConversionBasePath + '/view/:id',
  New: AlertBitByBitConversionBasePath + '/new'
};
export function getAlertConversionTypeByUrl(url: string): AlertConversionType {
  const type: AlertConversionType | null =
    url.indexOf(AlertCodeConversionTail) !== -1 ? 'AlertCodeConversion' :
    url.indexOf(AlertBitByBitConversionTail) !== -1 ? 'AlertBitByBitConversion' :
    url.indexOf(AlertOneToOneConversionTail) !== -1 ? 'AlertOneToOneConversion' : null;
  if (type === null) {
    throw new Error('Invalid Alert Conversion Url');
  }
  return type;
}

export function getAlertConversionPaths(type: AlertConversionType) {
  const paths =  type === 'AlertCodeConversion' ? RoutePaths.alertCodeConversionPaths :
    type === 'AlertBitByBitConversion' ? RoutePaths.alertBitByBitConversionPaths :
    type === 'AlertOneToOneConversion' ? RoutePaths.alertOneToOneConversionPaths : null;
  if (paths === null) {
    throw new Error(`Alert Conversion Type: ${type} is not supported`);
  }
  return paths;
}

const AlertBitByBitAssignmentGroupBasePath = ConfigurationPaths.globalConfiguration + '/alert-bit-by-bit-assignment-group';
const AlertBitByBitAssignmentGroupPaths = {
  Edit: AlertBitByBitAssignmentGroupBasePath + '/edit/:id',
  View: AlertBitByBitAssignmentGroupBasePath + '/view/:id',
  New: AlertBitByBitAssignmentGroupBasePath + '/new'
};

const SystemProcessTypePaths = {
  Edit: ConfigurationPaths.globalConfiguration + '/system-process-type/edit/:spTypeId',
  View: ConfigurationPaths.globalConfiguration + '/system-process-type/view/:spTypeId',
  New: ConfigurationPaths.globalConfiguration + '/system-process-type/new'
};

const ProfilePaths = {
  View: '/profile/view',
  Edit: '/profile/edit'
};

function makeStandardEditorPaths<T extends string>(basePath: T) {
  return {
    Edit: `${basePath}/edit/:id`,
    View: `${basePath}/view/:id`,
    New: `${basePath}/new`
  };
}
const ControllerTypePaths = makeStandardEditorPaths(`${ConfigurationPaths.globalConfiguration}/controller-type`);
const ControllerTypeRevisionPaths = makeStandardEditorPaths(`${ConfigurationPaths.globalConfiguration}/controller-type-revision/:controllerTypeId`);
const ConnectionKitPaths = makeStandardEditorPaths(`${ConfigurationPaths.globalConfiguration}/connection-kit`);
const ProtocolAdapterPaths = makeStandardEditorPaths(`${ConfigurationPaths.globalConfiguration}/protocol-adapter`);

export const RoutePaths = {
  ...HomePaths,
  ...MiscellaneousPaths,
  ...AuthenticationPaths,
  ...ConfigurationPaths,
  ...DashboardPaths,
  assetTemplatePaths: AssetTemplatePaths,
  spTemplatePaths: SpTemplatePaths,
  dataPointPaths: DataPointPaths,
  dealerConfigPaths: GlobalDealerConfigurationPaths,
  manufacturerPaths: ManufacturerPaths,
  globalCompanyConfigurationPaths: GlobalCompanyConfigurationPaths,
  rmxBoxPaths: RmxBoxPaths,
  importRmxBoxes: '/configuration/global/rmx-box/import',
  generateCertificates: '/configuration/global/rmx-box/generate-certificates',
  rmxBoxPortSettingsPaths: RmxBoxPortSettingsPaths,
  rmxBoxSourcePaths: RmxBoxSourcePaths,
  siteConfigurationPaths: SiteConfigurationPaths,
  installationConfigurationPaths: InstallationConfigurationPaths,
  assetGroupPaths: AssetGroupPaths,
  assetTypePaths: AssetTypePaths,
  assetRevisionPaths: AssetRevisionPaths,
  spRevisionPaths: SpRevisionPaths,
  systemProcessTypePaths: SystemProcessTypePaths,
  manufacturerOperationalStatusLabelsPaths: manufacturerOperationalStatusLabelsPaths,
  alertTypePaths: AlertTypePaths,
  dpaAlertPaths: DpaAlertPaths,
  ndpaAlertPaths: NdpaAlertPaths,
  alertCodeConversionPaths: AlertCodeConversionPaths,
  alertBitByBitConversionPaths: AlertBitByBitConversionPaths,
  alertOneToOneConversionPaths: AlertOneToOneConversionPaths,
  alertBitByBitAssignmentGroupPaths: AlertBitByBitAssignmentGroupPaths,
  userManagementPaths: UserManagementPaths,
  profilePaths: ProfilePaths,
  controllerTypePaths: ControllerTypePaths,
  controllerTypeRevisionPaths: ControllerTypeRevisionPaths,
  connectionKitPaths: ConnectionKitPaths,
  protocolAdapterPaths: ProtocolAdapterPaths
} as const;

export function useConfigurationBasePath(): string {
  const url = useCurrentUrl();
  const {companyId} = useParams();
   if (url.indexOf(RoutePaths.globalConfiguration) !== -1) {
    return RoutePaths.globalConfiguration;
  }
  return RoutePaths.companyConfiguration.replace(':companyId', companyId!);
}
