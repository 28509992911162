import {
  type DropdownOption,
  type DropdownOptions
} from 'src/components/util/form-components/SearchableDropdown/SearchableDropdown';
import {DateTime, type LocaleOptions} from 'luxon';
import {type HasName} from '../types/HasName';
import {type HasId} from '../types/Entity';
import {convertPascalToSentence} from './string';
import {type BaseSyntheticEvent} from 'react';
export * from './array';
export * from './string';
export * from './object';
export * from './url';

export function convertDictionaryToArray<T, P extends number | string >(obj: Record<P, T>): T[] {
  return Object.values(obj);
}

type DictionaryInferredReturn<T extends {}, K extends keyof T & string> = T[K] extends number ? {[key: number]: T} : T[K] extends string ? {[key: string]: T} : never;
export function convertArrayToDictionary<T extends {}, K extends keyof T & string>(arr: T[], key: K):
  DictionaryInferredReturn<T, K> {
  const obj: Record<string, T> = {};
  for (const item of arr) {
    obj[item[key] as string] = item;
  }
  return obj as DictionaryInferredReturn<T, K>;
}
export function arrayToRecord
<T, K extends string | number, V>(arr: T[], getKey: (i: T) => K, getValue: (i: T) => V): K extends string ? Record<string, V> : Record<number, V> {
  const obj: Record<string|number, V> = {};
  for (const item of arr) {
    obj[getKey(item)] = getValue(item);
  }
  return obj as K extends string ? Record<string, V> : Record<number, V>;
}

/**
 * Converts an array to a map object
 * @param arr the array to convert to map
 * @param getKey function to get the key
 * @param getValue If not specified, the value will be the item itself
 */
export function arrayToMap
<T, K, V = T>(arr: T[], getKey: (i: T) => K, getValue: (i: T) => V = (i: T) => i as unknown as V) {
  const map = new Map<K, V>();
  for (const item of arr) {
    map.set(getKey(item), getValue(item));
  }
  return map;
}

export function arrayToSet<T>(arr: T[]) {
  return new Set<T>(arr);
}


export type IdDictionary<TValue> = {[id: number]: TValue};
export type StringDictionary<TValue> = {[key: string]: TValue};

export function convertToDropDownOptions<T, V = number>(
  objs?: T[],
  labelProperty: keyof T = 'name' as keyof T,
  valueProperty: keyof T = 'id' as keyof T): Array<DropdownOption<V>> {
  if(isNullOrUndefined(objs)) {
    return [];
  }
  return objs.map(obj => convertObjectToOption<T, V>(obj, labelProperty, valueProperty));
}

export function convertStringConstantsToOptions<T extends string>(arr: T[]): DropdownOptions<T> {
  return arr.map<DropdownOption<T>>(s => ({
    value: s,
    label: convertPascalToSentence(s)
  }));
}

export function convertObjectToOption<T, V = number>(
  obj: T,
  labelProperty: keyof T = 'name' as keyof T,
  valueProperty: keyof T = 'id' as keyof T) {
  return ({value: obj[valueProperty], label: obj[labelProperty]}) as DropdownOption<V>;
}

export function convertToDropDownOptionByPredicate<T extends object, V extends number | string>(
  objs: T[],
  getLabel: (i: T) => string,
  getValue: ((i: T) => V)
): Array<DropdownOption<V>> {
  return objs.map(obj => ({label: getLabel(obj), value: getValue(obj)}));
}

export function convertArrayToDropDownOptions<T>(objs: T[], hideValues?: T[]): Array<DropdownOption<T>> {
   return objs.map(obj => ({value: obj, label: obj as any as string, hide: hideValues ? hideValues.some(h => h === obj) : false}) as DropdownOption<T>);
}

export function getObjectValues<T extends Record<keyof T, unknown>>(obj: T): Array<T[keyof T]> {
  return Object.keys(obj).map(key => obj[key as keyof T] as T[keyof T]);
}

export function getEnumValues<T extends Record<keyof T, any>>(obj: T): Array<keyof T> {
  return getObjectValues(obj);
}

export function groupBy<T, V>(list: T[], keyGetter: (type: T, index: number) => V): Map<V, T[]> {
  const map = new Map<V, T[]>();
  list.forEach((item, index) => {
    const key = keyGetter(item, index);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
}

export function groupByMany<T, V>(list: T[], keyGetter: (type: T) => V[]): Map<V, T[]> {
  const map = new Map<V, T[]>();
  list.forEach((item) => {
    const keys = keyGetter(item);
    for (const key of keys) {
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    }

  });
  return map;
}

export function nullable<T>(value: T | null): T | undefined {
  if (value) {
    return value;
  }
  return undefined;
}

export function replaceAtIndex<T>(arr: T[], index: number, value: T) {
  return [
    ...arr.slice(0, index),
    value,
    ...arr.slice(index + 1)
  ];
}

export function getLocalDateTimeString(dateTime: string)  {
  return DateTime.fromISO(dateTime.replace('Z', '') + '+00:00').toLocaleString(DateTime.DATETIME_MED as any as LocaleOptions);
}

export const ipv4AddressRegex = new RegExp([
  /^(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)/,
  /\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)/,
  /\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)/,
  /\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/]
  .map(e => e.source)
  .join('')
);

export const macAddressNoSymbolsRegex = new RegExp(/^[A-F0-9]{12}$/);

export function range(start: number, end: number) {
  const arr = [];
  for (let i = start; i <= end; i++) {
    arr.push(i);
  }
  return arr;
}

export function delay(timeout: number): Promise<void> {
  return new Promise(resolve => setTimeout(() => resolve(), timeout));
}

/**
 * use distinctArray instead in utils/array.ts
 * This is slower than distinctArray
 * @param value
 * @param index
 * @param self
 */
export function distinct<T>(value: T, index: number, self: T[]) {
  return self.indexOf(value) === index;
}

export function distinctBy<T, V>(values: T[], getter: (i: T) => V) {
  const mapped = values.map(i => getter(i));
  return values.filter((value: T, index: number) => mapped.indexOf(getter(value)) === index);
}

/**
 * Casts a constant value to a specific type.
 * Performs strict type check vs saying value as T performs a cast regardless if the value is a valid value of T
 */
export function cast<T>(value: T): T {
  return value;
}

export function conforms<T>() {
  return <S extends T>(value: S): S => {
    return value;
  };
}

export function isNull(v: any) {
  return v === null;
}

/**
 * Converts seconds to milliseconds
 * @param numSeconds
 */
export function seconds(numSeconds: number) {
  return numSeconds * 1000;
}

export const byName = (c1: HasName, c2: HasName) => c1.name.localeCompare(c2.name);
export const mapId = (i: HasId) => i.id;
export const mapIdStr = (i: HasId) => String(mapId(i));

export function optionalCall<T extends ((...args: any[]) => any)>(m: T |  undefined): T {
  if (m) {
    return m;
  }
  return (() => null) as T;
}

export function isEmptyOrWhiteSpace(str: string | undefined | null) {
  return str === undefined || str === null || str.match(/^ *$/) !== null;
}

/**
 * Check whether the value equals null or does not equal null in one call.
 * This is helpful for short filter statements
 * examples:
 * getNullEquality(true, null) -> true // equals null and should
 * getNullEquality(false, null) -> false // does equal null but shouldn't
 * getNullEquality(true, 'hi') -> false // does not equal null but should
 * getNullEquality(false, 'hi') -> true // Check whet
 * @param equalsNull
 * @param value
 */
export function getNullEquality(equalsNull: boolean, value: any) {
  return !equalsNull ? value !== null : value === null;
}

export function optional<T extends Record<string, unknown>>(
  bool: any,
  val: T
): Record<string, unknown> {
  return bool ? val : {};
}

export function isNullOrUndefined<T>(v: T | null | undefined) : v is null | undefined {
  return !isNotNullOrUndefined(v);
}

export function isNotNullOrUndefined<T>(v: T | null | undefined) : v is T {
  return v !== undefined && v !== null;
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function throwErr<T>(mssg: string): never {
  throw new Error(mssg);
}


export type RequiredKeys<T> = {
  [K in keyof T]: ({} extends { [P in K]: T[K] } ? never : K)
}[keyof T];

export type OmitOptional<T> = Pick<T, RequiredKeys<T>>;

export function preventDefault(e: BaseSyntheticEvent) {
  e.preventDefault();
}


export function ifNaN<T extends number | null>(value: number, defaultValue: T) {
  return isNaN(value) ? defaultValue : value;
}


export function noop() {
}
