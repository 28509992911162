import {createContext, useContext} from 'react';

export function makeContext<T>(defaultValue: T) {
  const context = createContext<T>(defaultValue);
  return [
    context.Provider,
    () => useContext(context),
    context
  ] as const;
}
