import React, { type ReactNode } from 'react';
import { type BreadCrumbData } from 'src/api/userDashboardApi';
import { type IconProp } from '@fortawesome/fontawesome-svg-core';
import { GlobalSearch, type GlobalSearchState } from '../../../pages/Dashboard/GlobalSearch/GlobalSearch';
import { Panel } from '../Panel/Panel';
import s from './PageLayout.module.scss';
import { HeaderBackButton, HeaderButton } from '../widgets/HeaderButton/HeaderButton';
import clsx from 'clsx';
import { DashboardBreadCrumbs } from '../../../pages/Dashboard/DashboardBreadCrumbs/DashboardBreadCrumbs';
import { useApplicationType } from '../../../ApplicationTypeContext';
type Props = {
  children?: ReactNode;
  className?: string;
  darker?: boolean;
  breadCrumbs?: BreadCrumbData[];
  backLink?: string;
  buttons?: IconButtonProps[];
  searchState?: GlobalSearchState;
  headerLeft?: ReactNode;
};

export type IconButtonProps = { icon: IconProp; to: string; newTab?: boolean; tooltip: string };

export function PanelLayout(props: Props) {
  const { backLink, buttons, breadCrumbs } = props;
  const appType = useApplicationType();
  return (
    <Panel
      style={{ ...(appType === 'Portal' ? {margin: 0} : {})}}
      {...props}
      bodyClassName={clsx(breadCrumbs?.length && 'pt-0')}
      className={clsx(s['layout'], props.className)}
      headerLeft={<>
        {backLink && <HeaderBackButton link={backLink} />}
        {props.headerLeft ? props.headerLeft : breadCrumbs?.length ? <h5 className="ms-2 mb-0 fw-normal">{breadCrumbs[breadCrumbs.length - 1].label}</h5> : null}
      </>}
      headerRight={<>
        {buttons?.map((b, index) => (
          <HeaderButton key={index} link={b.to} icon={b.icon} tooltip={b.tooltip} newTab={b.newTab} />
        ))}
        {props.searchState && <>
          <GlobalSearch state={props.searchState} />
        </>}
      </>}
    >
      {breadCrumbs && <DashboardBreadCrumbs breadCrumbs={breadCrumbs} className="pb-4 pt-2" />}
      {props.children}
    </Panel>
  );
}
