import { type LoadableResourceState } from 'src/hooks/useLoadableResource';
import { CenteredSpinner } from './widgets/CenteredSpinner/CenteredSpinner';
import React, { type ReactNode } from 'react';
import { Alert } from './Controls';
import { type UseQueryResult } from '@tanstack/react-query';
import { handleAxiosError } from '../../common/util/http';

// eslint-disable-next-line @typescript-eslint/ban-types
function isFunction(functionToCheck: any): functionToCheck is () => void {
  return functionToCheck ? {}.toString.call(functionToCheck) === '[object Function]' : false;
}

export function LoadableResourceLayout<T>({ state, children, ...p }: { state: LoadableResourceState<T>; globalSpinner?: boolean; children: any }) {
  const globalSpinner = p.globalSpinner === undefined ? false : p.globalSpinner;
  const { errorMessage, loading, alert, resource } = state;
  return errorMessage ? <Alert variant="danger">{errorMessage}</Alert>
    : loading
      ? <CenteredSpinner global={globalSpinner} />
      : (
        <>
          {alert && <Alert variant="danger">{alert}</Alert>}
          {resource !== undefined && (isFunction(children) ? children() : children)}
        </>
      );
}

type ReactQueryLayoutProps<T> = {
  state: UseQueryResult<T, Error>;
  globalSpinner?: boolean;
  children: ReactNode | (() => ReactNode);
  shrink?: boolean;
  showIfNoData?: boolean;
  showLoaderOnRefetch?: boolean;
};

export function ReactQueryLayout<T>({ state, children, shrink, showIfNoData, showLoaderOnRefetch, ...p }: ReactQueryLayoutProps<T>) {
  const globalSpinner = p.globalSpinner === undefined ? false : p.globalSpinner;
  const { error, isLoading, data, isFetching } = state;
  const shouldShowLoader = showLoaderOnRefetch ? isFetching: isLoading ;
  return (
    <>
      {error && <Alert variant="danger">{handleAxiosError(error)}</Alert>}
      {shouldShowLoader
        ? <CenteredSpinner global={globalSpinner} minHeight={!shrink ? '50vh' : undefined} />
        : (
          <>
            {(data !== undefined || showIfNoData) && (isFunction(children) ? children() : children)}
          </>
        )}
    </>
  );
}

type AggregateReactQueryLayoutProps = { state: UseQueryResult<unknown, Error>[]; globalSpinner?: boolean; children: ReactNode | (() => ReactNode); section?: boolean };

export function AggregateReactQueryLayout({ state, children, section, ...p }: AggregateReactQueryLayoutProps) {
  const globalSpinner = p.globalSpinner === undefined ? false : p.globalSpinner;
  const error = state.filter(s => s.error).map(s => s.error)[0];
  const isLoading = state.filter(s => s.isLoading).length > 0;
  const dataDefined = state.filter(s => s.data !== undefined).length > 0;
  return (
    <>
      {error && <Alert variant="danger">{handleAxiosError(error)}</Alert>}
      {isLoading
        ? <CenteredSpinner global={globalSpinner} minHeight={!section ? '50vh' : undefined} />
        : (
          <>
            {dataDefined !== undefined && (isFunction(children) ? children() : children)}
          </>
        )}
    </>
  );
}
