// tslint:disable-next-line:no-import-side-effect
import './index.scss';
// tslint:disable-next-line:no-import-side-effect
import './bootstrap/custom.min.css'; // not actually minified but needs to be called that to prevent webstorm from parsing it.
import 'react-app-polyfill/stable';
// tslint:disable-next-line:no-import-side-effect
import 'react-datetime/css/react-datetime.css';
// tslint:disable-next-line:no-import-side-effect
import './common/util/array';
import React from 'react';

import * as serviceWorker from './serviceWorker';
import {configureFontAwesome} from './font-awesome-config';
import App from './App';
import {Provider} from 'react-redux';
import {PersistGate} from 'redux-persist/integration/react';
import { store, persistor } from './redux/store';
import {validateEnvironment} from 'src/env/validateEnvironment';
import {configureTheme} from './appTheme';
import LogRocket from 'logrocket';
import {createRoot} from 'react-dom/client';
import {initSentry} from 'src/util/sentryProvider';
import UserEffectComponent from './components/util/UserEffectComponent/UserEffectComponent';
import { ApplicationTypeProvider } from './ApplicationTypeContext';

initSentry();
validateEnvironment();
configureTheme();
configureFontAwesome();

if (import.meta.env.VITE_SESSION_RECORDING_ENABLED.toLowerCase() === 'true') {
  LogRocket.init('fyovu6/procura-iot', {
    shouldDetectExceptions: false
  });
}
const root = createRoot(document.getElementById('root')!);
root.render(
  <React.StrictMode>
    <ApplicationTypeProvider value='Support'>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <UserEffectComponent>
          <App />
        </UserEffectComponent>
      </PersistGate>
    </Provider>
    </ApplicationTypeProvider>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
