import {type RmxBox, type RmxBoxPort, type RmxBoxSource, type UnsavedRmxBox} from '../common/types/RmxBox';
import {type Installation} from '../common/redux/entities/installation';
import {type AssetRevision} from '../common/redux/entities/installationEntities/assetRevision';
import {api} from './index';

export async function saveRmxBox(rmxBox: UnsavedRmxBox) {
  return (await api.post<RmxBoxChangeResponse>('RmxBox', rmxBox)).data;
}

export interface RmxBoxChangeResponse {
  rmxBox: RmxBox;
  installation: Installation | null;
  assetRevisions: AssetRevision[];
}

export async function saveRmxBoxPort(rmxBox: RmxBoxPort) {
  return (await api.post<RmxBoxChangeResponse>('RmxBoxRevision/Port', rmxBox)).data;
}

export async function saveRmxBoxSource(source: RmxBoxSource) {
  return (await api.post<RmxBoxChangeResponse>('RmxBoxRevision/Source', source)).data;
}


export async function uploadRmxBoxes(uploadData: FormData) {
  return await api.post('/RmxBox/Upload', uploadData, {
    headers: {
      'Content-Type': 'multipart/form-data;'
    }
  });
}
export async function generateCertificates(serialNumbers: string[]) {
  const resp = await api.post<Blob>('/certificate-management/generate-batch-certificates', {
    serialNumbers: serialNumbers
  }, {
    responseType: 'blob'
  });
  const contentDisposition = resp.headers['content-disposition'] as string|undefined;
  if (!contentDisposition) {
    throw new Error('No content disposition header');
  }
  const fileName = contentDisposition.split(';')[1].split('=')[1];
  if(!fileName){
    throw new Error('No filename in content disposition header');
  }
  return [resp.data, fileName] as const;
}
